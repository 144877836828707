<template>
  <div class="update-talent">
    <div class="container">
      <div class="update-talent__wrapper">
        <div class="update-talent__header">
          <h1>
            {{ $t('contractsRenewal.pleaseUpdateAvailability') }}
          </h1>
          <span>
            {{ $t('contractsRenewal.pleaseUpdateAvailabilityInfo') }}
          </span>
        </div>

        <div v-if="!isLoaded" class="tw-my-8 tw-flex tw-items-center tw-justify-center">
          <div class="pagination-loader"></div>
        </div>

        <form v-else @submit.prevent="submit" class="update-talent__form">
          <div
            class="update-talent__item tw-border-b"
            :class="{ 'update-talent__item--disabled': !allowEditFullTime }"
            v-tooltip="{
              content: $t('talent.youAlreadyAssignedToFullTimeContract'),
              visible: !allowEditFullTime,
            }"
          >
            <div
              class="update-talent__itemHeader tw-w-full tw-flex tw-items-center tw-justify-center"
            >
              <div class="tw-flex tw-gap-12 tw-w-full tw-justify-between md:tw-justify-start">
                <h3>
                  {{ $t('contractsRenewal.fullTime') }}
                </h3>
                <toggle-button v-model="fullTimeOn"
                  :color="{ unchecked: '#F4F1F8', checked: '#F0ECFF' }"
                  :switch-color="{ unchecked: '#000', checked: '#422E87' }" :margin="1" :height="25"
                  :width="50" :sync="true" :labels="false" />
              </div>
              <div class="update-talent__availability" v-if="!availabilityOptionsLoading">
                <v-select v-model="fullTimeAvailability" :reduce="(name) => name.id"
                  class="style-chooser" :disabled="!fullTimeOn"
                  :placeholder="$t('clientWizard.chooseAvailability')" :options="availabilitiesList"
                  label="name">
                  <div slot="no-options">
                    {{ $t("talent.sorryNoMatchingOptions") }}
                  </div>
                </v-select>
                <div class="error-select-validation-message">
                  <VulidateError :validationField="$v.fullTimeAvailability"
                    :params="['required']" />
                </div>
              </div>
            </div>
            <div class="tw-col-span-2 floating-label">
              <label for="fullTimeSalary"
                class="tw-text-[#292929] tw-font-semibold tw-text-[12.91px]">
                {{ $t('contractsRenewal.monthlyFullTimeSalary') }}
              </label>
              <input id="fullTimeSalary" class="px-2 w-100 form-control" :class="{
                  'has-error': (fullTimeSalary <= 0 || fullTimeSalary == null),
                  'tw-cursor-not-allowed': !fullTimeOn
                }" required :disabled="!fullTimeOn" v-model="fullTimeSalary"
                type="number" @wheel.prevent />
              <span class="info-message">
                {{ $t('contractsRenewal.usd') }}
              </span>
              <span v-if="fullTimeSalary == null && isFullTimeSalaryChanged && fullTimeOn"
                class="tw-text-[#FF6F59]">
                {{ $t('contractsRenewal.required') }}
              </span>
              <span v-else-if="fullTimeSalary <= 0 && isFullTimeSalaryChanged && fullTimeOn"
                class="tw-text-[#FF6F59]">
                {{ $t('contractsRenewal.cantBeLessThan1Usd') }}
              </span>
              <span v-else-if="fullTimeSalary > 100000 && isFullTimeSalaryChanged && fullTimeOn"
                class="tw-text-[#FF6F59]">
                {{ $t('contractsRenewal.cantBeMoreThan100000Usd') }}
              </span>
            </div>
            <div v-if="false" class="update-talent__progress-wrapper tw-mt-5">
              <p class="tw-text-sm tw-font-semibold tw-text-[#000000]">
                {{ $t('contractsRenewal.lessThanAverage') }}
              </p>
              <div class="tw-flex tw-items-center tw-justify-content tw-gap-1 tw-my-2">
                <div class="tw-w-1/2 tw-rounded-lg tw-h-2 tw-bg-[#FFC629]"></div>
                <div class="tw-w-1/2 tw-rounded-lg tw-h-2 tw-bg-[#00000033]"></div>
              </div>
              <p class="tw-text-sm tw-font-semibold">
                {{ $t('contractsRenewal.lessThanAverageInfo') }}
              </p>
            </div>
          </div>
          <div
            class="update-talent__item tw-border-b"
            :class="{ 'update-talent__item--disabled': !allowEditPartTime }"
            v-tooltip="{
              content: $t('talent.youAlreadyAssignedToPartTimeContract'),
              visible: !allowEditPartTime,
            }"
          >
            <div
              class="update-talent__itemHeader tw-w-full tw-flex tw-items-center tw-justify-center">
              <div class="tw-flex tw-gap-12 tw-w-full tw-justify-between md:tw-justify-start">
                <h3>
                  {{ $t('contractsRenewal.partTime') }}
                </h3>
                <toggle-button v-model="partTimeOn"
                  :color="{ unchecked: '#F4F1F8', checked: '#F0ECFF' }"
                  :switch-color="{ unchecked: '#000', checked: '#422E87' }" :margin="1" :height="25"
                  :width="50" :sync="true" :labels="false" />
              </div>
              <div class="update-talent__availability" v-if="!availabilityOptionsLoading">
                <v-select v-model="partTimeAvailability" :reduce="(name) => name.id"
                  class="style-chooser" :disabled="!partTimeOn"
                  :placeholder="$t('clientWizard.chooseAvailability')" :options="availabilitiesList"
                  label="name">
                  <div slot="no-options">
                    {{ $t("talent.sorryNoMatchingOptions") }}
                  </div>
                </v-select>
                <div class="error-select-validation-message">
                  <VulidateError :validationField="$v.partTimeAvailability"
                    :params="['required']" />
                </div>
              </div>
            </div>
            <div class="tw-col-span-2 floating-label">
              <label for="partTimeSalary"
                class="tw-text-[#292929] tw-font-semibold tw-text-[12.91px]">
                {{ $t('contractsRenewal.monthlyPartTimeSalary') }}
              </label>
              <input id="partTimeSalary" class="px-2 w-100 form-control" :class="{
                  'has-error': (partTimeSalary <= 0 || partTimeSalary == null),
                  'tw-cursor-not-allowed': !partTimeOn
                }" required :disabled="!partTimeOn" v-model="partTimeSalary"
                type="number" @wheel.prevent />
              <span class="info-message">
                {{ $t('contractsRenewal.usd') }}
              </span>
              <span v-if="partTimeSalary == null && isPartTimeSalaryChanged && partTimeOn"
                class="tw-text-[#FF6F59]">
                {{ $t('contractsRenewal.required') }}
              </span>
              <span v-else-if="partTimeSalary <= 0 && isPartTimeSalaryChanged && partTimeOn"
                class="tw-text-[#FF6F59]">
                {{ $t('contractsRenewal.cantBeLessThan1Usd') }}
              </span>
              <span v-else-if="partTimeSalary > 100000 && isPartTimeSalaryChanged && partTimeOn"
                class="tw-text-[#FF6F59]">
                {{ $t('contractsRenewal.cantBeMoreThan100000Usd') }}
              </span>
            </div>
          </div>

          <div class="update-talent__item" :class="{'tw-border-b': Number(profile.confirmed_selections_count) > 0}">
            <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
              <div class="">
                <label class="form-label required tw-font-semibold">
                  {{ $t("talent.whatIsYourCountry") }}
                </label>
                <SelectCountry
                  :placeholder="$t('talent.whatIsYourCountry')"
                  :validationField="$v.country"
                  hideLabel="true"
                  :selectOptions="countryList"
                  keySelected="defaultCountryName"
                />
              </div>
              <div class="">
                <label class="form-label required tw-font-semibold">
                  {{ $t("talent.whatIsYourNationality") }}
                </label>
                <SelectCountry
                  :placeholder="$t('talent.whatIsYourNationality')"
                  :validationField="$v.nationality"
                  hideLabel="true"
                  :selectOptions="nationalitiesList"
                  keySelected="defaultCountryName"
                />
              </div>
            </div>
          </div>

          <div
            v-if="Number(profile.confirmed_selections_count) > 0"
            class="tw-bg-[#F1EFFD] tw-rounded-md tw-p-5 tw-mb-5 tw-flex tw-items-start tw-gap-3"
          >
            <img src="@/assets/images/info-icon.svg" alt="Info icon" class="tw-w-8 tw-h-8" />
            <p class="tw-text-[#422E87] tw-text-[14px]">
              {{ $t('talent.warningBeforeTalentUpdate') }}
            </p>
          </div>

          <div class="tw-flex tw-justify-end tw-mt-5 tw-gap-3">
            <button type="button"
              class="tw-border tw-border-[#FFC629] tw-text-[#FFC629] tw-py-2 tw-px-4 tw-rounded-lg tw-font-semibold tw-text-[14px] tw-w-[170px] tw-text-center tw-px-3 tw-py-3"
              @click="$router.push({name: 'Profile'})">
              {{ $t('contractsRenewal.cancel') }}
            </button>
            <button :disabled="isLoading" type="submit"
              class="tw-bg-[#FFC629] tw-py-2 tw-px-4 tw-rounded-lg tw-font-semibold tw-text-[14px] tw-w-[170px] tw-text-center tw-px-3 tw-py-3">
              <!-- <span v-if="!isLoading"> -->
              {{ $t('signUp.signUp') }}
              <!-- </span> -->
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import toast from "../../../services/toast";
import { mapGetters } from 'vuex';
import { ToggleButton } from "vue-js-toggle-button";
// eslint-disable-next-line no-unused-vars
import { required } from "vuelidate/lib/validators";
import SelectCountry from "@/pages/Talent/profile/components/editPersonalInfo/selectCountry.vue";
import VulidateError from "@/components/shared/VulidateError/vulidateError.vue";

export default {
  components: {
    ToggleButton,
    VulidateError,
    SelectCountry,
  },
  data() {
    return {
      fullTimeOn: true,
      partTimeOn: false,
      availabilityOptions: [
        { id: 0, name: "locked" },
        { id: 1, name: "immediately" },
        { id: 2, name: "in1Week" },
        { id: 3, name: "in2Weeks" },
        { id: 4, name: "in3Weeks" },
        { id: 5, name: "in4Weeks" },
        { id: 6, name: "in5Weeks" },
        { id: 7, name: "in6Weeks" },
        { id: 8, name: "in7Weeks" },
        { id: 9, name: "in8Weeks" },
        { id: 10, name: "in9Weeks" },
        { id: 11, name: "in10Weeks" }
      ],
      fullTimeAvailability: null,
      partTimeAvailability: 0,
      fullTimeSalary: null,
      partTimeSalary: null,
      defaultCountryCode: 'SA',
      country: null,
      nationality: null,
      isFullTimeSalaryChanged: false,
      isPartTimeSalaryChanged: false,
      isLoading: false,
      isLoaded: false,
      availabilityOptionsLoading: false,
    };
  },
  validations: {
    fullTimeAvailability: {
      required(val) {
        return !this.fullTimeOn || !!val || val == 0;
      }
    },
    partTimeAvailability: {
      required(val) {
        return !this.partTimeOn || !!val || val == 0;
      }
    },
    country: {
      required,
    },
    nationality: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      profile: "talentProfile/getProfileData",
    }),
    availabilitiesList() {
      return this.availabilityOptions.map( Option =>   { return {id: Option.id, name: this.$t(`availabilityOptions.${[Option.name]}`)} }  );
    },
    allowEditFullTime() {
      return this.profile.basicInfo.allow_edit_availability.allow_full_time;
    },
    allowEditPartTime() {
      return this.profile.basicInfo.allow_edit_availability.allow_part_time;
    },
    nationalitiesList() {
      return this.$store.getters["shared/getCountryList"].map((country) => ({
        ...country,
        defaultCountryName: country.id,
        name: this.$i18n.locale == "en" ? country.nationality_en : country.nationality_ar
      }));
    },
    countryList() {
      return this.$store.getters["shared/getCountryList"].map((country) => ({
        ...country,
        defaultCountryName: country.id,
        name: this.$i18n.locale == "en" ? country.name_en : country.name_ar,
      }));
    },
  },
  mounted() {
    this.$store.dispatch("shared/getCountriesRequest");
    this.getProfileDataRequest()    
  },
  watch: {
    'profile.basicInfo': function(basic_info) {
      if (basic_info !== undefined && basic_info !== null) {
        if (this.fullTimeOn) {
          this.fullTimeSalary = basic_info.salary;
          this.fullTimeAvailability = basic_info.full_time_status;
        } else {
          this.fullTimeSalary = null;
          this.fullTimeAvailability = 0;
        }

        if (this.partTimeOn) {
          this.partTimeAvailability = basic_info.part_time_status;
          this.partTimeSalary = basic_info.part_time_salary;
        } else {
          this.partTimeSalary = null;
          this.partTimeAvailability = 0;
        }

        this.fullTimeOn = !!Number(basic_info.full_time_status)
        this.partTimeOn = !!Number(basic_info.part_time_status)

        let countrySelected = this.countryList?.find(
          (ele) => ele.defaultCountryName === basic_info.country
        );
        this.defaultCountryCode = countrySelected?.code;
      }
    },
    "country": {
      handler(newVal) {
        let countrySelected = this.countryList?.find(
          (ele) => ele.id === newVal
        );
        this.defaultCountryCode = countrySelected?.code;
      },
      deep: true,
    },
    "$i18n.locale": {
      immediate: true,
      deep: true,
      handler() {
        this.availabilityOptionsLoading = true
        setTimeout(() => {
          this.availabilityOptionsLoading = false
        }, 100);
      },
    },
    fullTimeAvailability(newVal) {
      if (newVal == 0) {
        this.fullTimeOn = false;
      }
    },
    partTimeAvailability(newVal) {
      if (newVal == 0) {
        this.partTimeOn = false;
      }
    },
    fullTimeSalary(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isFullTimeSalaryChanged = true;
      }
    },
    partTimeSalary(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isPartTimeSalaryChanged = true;
      }
    },
    fullTimeOn(newVal) {
      if (!this.allowEditFullTime) {
        this.fullTimeOn = false;
        return;
      }

      if (!newVal) {
        this.fullTimeSalary = null;
        this.fullTimeAvailability = 0;
        this.isFullTimeSalaryChanged = false;
      } else {
        this.fullTimeAvailability = null;
        this.fullTimeSalary = this.profile.basicInfo.salary;
      }
    },
    partTimeOn(newVal) {
      if (!this.allowEditPartTime) {
        this.partTimeOn = false;
        return;
      }

      if (!newVal) {
        this.partTimeSalary = null;
        this.partTimeAvailability = 0;
        this.isPartTimeSalaryChanged = false;
      } else {
        this.partTimeAvailability = null;
        this.partTimeSalary = this.profile.basicInfo.part_time_salary;
      }
    },
  },
  methods: {
    async getProfileDataRequest() {
      this.$store.dispatch("talentProfile/getProfileDataRequest").then((res) => {
        
        this.isLoaded = true;
        if (res.basicInfo !== undefined && res.basicInfo !== null) {   
        this.fullTimeSalary = res.basicInfo.salary;
        this.fullTimeAvailability = res.basicInfo.full_time_status;
        this.partTimeAvailability = res.basicInfo.part_time_status;
        this.partTimeSalary = res.basicInfo.part_time_salary;
        
        this.country = res.basicInfo.country?.id || null;
        this.nationality = res.basicInfo.nationality?.id || null;

        this.fullTimeOn = !!Number(res.basicInfo.full_time_status);
        this.partTimeOn = !!Number(res.basicInfo.part_time_status);
      }

      });
    },
    updateAvailabilityIsValid() {
      if (this.fullTimeOn) {
        // Check full time fields
        if (!this.fullTimeAvailability ||
          !this.fullTimeSalary ||
          this.fullTimeSalary <= 0 ||
          this.fullTimeSalary > 100000) {
          return false;
        }
      }

      if (this.partTimeOn) {
        // Check part time fields
        if (!this.partTimeAvailability ||
          !this.partTimeSalary ||
          this.partTimeSalary <= 0 ||
          this.partTimeSalary > 100000) {
          return false;
        }
      }

      // If neither is on, or all required fields are valid
      return true;
    },

    async submit() {
      this.$v.$touch();
      if (!this.updateAvailabilityIsValid() || this.$v.$invalid) {
        return;
      }
      this.isLoading = true;

      const payload = {};

      if (this.fullTimeOn && this.allowEditFullTime) {
        payload.full_time_salary = this.fullTimeSalary;
        payload.full_time_status = this.fullTimeAvailability;
      } else if (this.allowEditFullTime) {
        payload.full_time_status = 0;
      }

      if (this.partTimeOn && this.allowEditPartTime) {
        payload.part_time_salary = this.partTimeSalary;
        payload.part_time_status = this.partTimeAvailability;
      } else if (this.allowEditPartTime) {
        payload.part_time_status = 0;
      }

      payload.country_id = this.country.id ? this.country.id : this.country;
      payload.nationality_id = this.nationality.id ? this.nationality.id : this.nationality;

      Object.keys(payload).forEach((key) => {
        if (payload[key] === null) {
          delete payload[key];
        }
      });

      await this.axios.post("/api/users/profile", payload)
        .then(() => {
          toast.success(this.$t('talentWizard.profileAvailabilityUpdated'));
          this.$router.push({ name: 'Profile', query: { status: 'tour' } });
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(error.response.data.errors?.[0]?.error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.update-talent {
  padding: 80px 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    border-radius: 20px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 28px;
    background-color: #fff;
    position: relative;
  }
  &__header {
    padding-bottom: 12px;
    border-bottom: 1px solid #00000030;
    h1 {
      font-size: 32px;
      font-weight: 700;
      color: #422E87;
    }

    span {
      display: inline-block;
      max-width: 650px;
      margin-top: 5px;
      font-size: 16px;
      color: #8D8D8D;
    }
  }

  &__item {
    padding: 12px 0 36px;
    margin: 24px 0;

    &--disabled {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        cursor: not-allowed;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(1.3px);
        -webkit-backdrop-filter: blur(1.3px);
      }
    }
  }

  &__itemHeader {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    padding-bottom: 12px;

    @media (min-width: 667px) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding-bottom: 0;
    }
  }

  h3 {
    font-size: 22px;
    font-weight: 500;
    color: #000000;
  }

  &__availability {
    width: 100%;
    @media (min-width: 667px) {
      max-width: 240px;
      margin-inline-start: auto;
    }
  }
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}



.floating-label {
  position: relative;
  span.info-message {
    position: absolute;
    bottom: 8px;
    right: 10px;
    font-size: 16px;
    color: #9f9d9d;
    padding-block: 3px;
    padding-inline-start: 10px;
    border-left: 1px solid #d9d9d9;
  }
  span:not(.info-message) {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


html[dir="rtl"] {
  .floating-label {
    span.info-message {
      right: auto;
      left: 10px;
      border-left: 0;
      border-right: 1px solid #d9d9d9;
    }
  }
}


.pagination-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid rgba(255, 198, 41, 1);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<style lang="scss">
.country-selector__list.slide-enter,
.country-selector__list.slide-leave-to {
  transition-duration: 0s !important;
  display: none;
  opacity: 0;
}

.country-selector__list.slide-enter-active,
.country-selector__list.slide-leave-active {
  transition-duration: 0s !important;
  display: none;
  opacity: 0;
}
</style>
